<template>
  <!-- [begin::popup-container] -->
  <div class="lxp-layout">
    <div v-if="modelValue===true" class="popup-container" :class="{ 'is-active': modelValue }">
      <!-- popup -->
      <div class="popup_" >
        <!-- popup > popup-inner -->
        <div id="pop-wrap">
          <!-- header  -->
          <div id="pop-header">
            <h2>검색 결과</h2>
          </div>
          <!-- container01  -->
          <div id="container01" class="mtop15">
            <table id="search-member-tbl" class="tType01" summary="검색 결과">
              <colgroup>
                <col width="25%" />
                <col width="25%" />
                <col width="20%" />
                <col width="20%" />
                <col width="*" />
              </colgroup>
              <thead>
              <tr>
                <th>계열사명</th>
                <th>부서명</th>
                <th>직위</th>
                <th>이름</th>
                <th>선택</th>
              </tr>
              </thead>
              <tbody>
              <template v-if="items.length > 0">
              <tr v-for="(item, index) in items" :key="index" >
                <td>국민은행</td>
                <td>{{ item.deptNm }}</td>
                <td>{{ item.jbgdNm }}({{ item.jbpsNm }})</td>
                <td>{{ item.lrnerNm }}</td>
                <td>
                  <input @click="changeLerner(item)" type="radio" name="selectMember" value="{{itme.lrnerId}}" />
                </td>
              </tr>
              </template>
              <template v-else>

                <td colspan="5" class="text-center" >
                  <span class="text">검색 조회 결과가 없습니다.</span>
                </td>
              </template>

              </tbody>
            </table>
            <div class="kb-btn-wrap01">
              <a type="button" @click="selectBtn" class="kb-btn-01 w70">선택</a >
              <a type="button" @click="closeModal" class="kb-btn-01  w70">닫기</a>
            </div>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <!--        <div class="popup-close">-->
      <!--          <button class="kb-btn-popup-close" @click="closeModal">-->
      <!--            <i class="icon-popup-close">팝업닫기</i>-->
      <!--          </button>-->
      <!--        </div>-->
    </div>
    <!-- //popup -->
  </div>
  <!-- [end::popup-container] -->

</template>

<script>

//
//import {ref} from "vue";

import {reactive} from "vue";

export default {
  name: "WiseTrustSearchModal",
  props: {
    modelValue: Boolean,
    items: Array,
    selected: Object,
    isLoading: Boolean,
  },
  emits: ["update:modelValue","update:items","selectedLrner"],
  setup(props, { emit }) {


    const selectLrner = reactive({
      deptNm: '부서',
      lrnerNm: '심쿵비비',
      lrnerId:'',
      jbpsNm:'스타',
      jbgdNm:'프렌즈',
      thumb: ''

    });

    const changeLerner = (item) => {
      selectLrner.deptNm = item.deptNm;
      selectLrner.lrnerNm = item.lrnerNm;
      selectLrner.lrnerId = item.lrnerId;
      selectLrner.jbpsNm = item.jbpsNm;
      selectLrner.jbgdNm = item.jbgdNm;
      selectLrner.thumb = `${process.env.VUE_APP_CLOUDFRONT_URL}`+"/contents/emp_img/current/"+item.lrnerId+".jpg";
    };
    const closeModal = () => {

      emit("update:modelValue", false);
      emit("update:items", []);
    };

    const selectBtn = ()=>{
      if (selectLrner.lrnerId ==='') {
        alert('칭찬할 직원을 선택해 주세요');
      }else{
        console.log(selectLrner);
        emit("selectedLrner", selectLrner);

        closeModal();

      }

    }



    return {
      closeModal,
      selectBtn,
      selectLrner,
      changeLerner,
    };
  },
};
</script>