<template>
  <!-- [begin::popup-container] -->
  <div class="lxp-layout">
    <div v-if="modelValue===true" class="popup-container" :class="{ 'is-active': modelValue }">
      <!-- popup -->
      <div class="popup_" style="width:100%;max-width: 600px;">
        <!-- popup > popup-inner -->
        <div id="pop-wrap">
          <!-- header  -->
          <div id="container01" class="mtop15">

            <section class="intent-wrap">
              <h3 class="cont-left bg01" id="exampleTitle">
                <!-- <span>6월의 칭찬 추천 문구</span> -->
                <span>{{mainTitle}}</span></h3>

              <article class="article-dic" style="overflow-y: scroll">
                <div class="side type2">
                  <ul class="accordion" id="acoList">
                    <li v-for="(item, idx) in subTitle" :key="idx" class="more" :class="{ 'active' : idx === activeIndex  }">
                      <a  @click="getExampleList(idx)" class="more" style="height:52px;">{{item.value}} </a>
                      <div  v-if="activeSubKey ===item.key" class="accordion-target">
                        <ul v-for="(subItem, idx2) in subExampleList"  :key="idx2">
                          <li class="nom" @click="setExample(subItem.value)" >
                            <a >{{subItem.value}}</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </article>

            </section>

          </div>
          <div class="kb-btn-wrap01">
            <a type="button" @click="closeModal" class="kb-btn-01  w70">닫기</a>
          </div>

          </div>
        </div>
      </div>

  </div>
  <!-- [end::popup-container] -->

</template>

<script>

//
import {ref} from "vue";
// import {ACT_GET_WISE_SUB_EXAMPLE_LIST} from "@/store/modules/wise/wise";
// import {getItem, lengthCheck} from "@/assets/js/util";
// import {useStore} from "vuex";

// import {reactive} from "vue";

export default {
  name: "WiseTrustExampleModal",
  props: {
    modelValue: Boolean,
    isLoading: Boolean,
    subTitle: Array,
    mainTitle: String,
    subExample: Array,
  },
  emits: ["update:modelValue","exampleText"],
  setup(props, { emit }) {

    const subExampleList = ref([]);
    // const store = useStore();
    const activeIndex = ref(null);
    const activeSubKey = ref(null);
    const closeModal = () => {

      emit("update:modelValue", false);

    };



    const getExampleList = async (idx) => {

      if(activeIndex.value === idx){
        activeIndex.value = null;
        subExampleList.value = null;
      }else{

        activeSubKey.value = props.subTitle[idx].key;
        subExampleList.value =props.subExample.filter( x => x.key === activeSubKey.value);
        activeIndex.value = idx;
      }
    }


   const setExample = (text)=>{
     emit("exampleText", text);
   }


    return {
      closeModal,
      setExample,
      getExampleList,
      activeIndex,
      subExampleList,
      activeSubKey,
    };
  },
};
</script>